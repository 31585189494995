import type { CityMap, Position } from '../types';

export const currentLayout: CityMap = {
  width: 27.5,
  depth: 14.5,
  holes: [
    { x: 4, y: 4, width: 2, depth: 3.5 },
    { x: 12, y: 4, width: 2, depth: 3.5 },
    { x: 20, y: 4, width: 2, depth: 3.5 },
  ],
};

export const newLayout: CityMap = {
  width: 28,
  depth: 38.5,
  holes: [
    { x: 7, y: 7, width: 3.5, depth: 3 },
    { x: 17.5, y: 7, width: 3.5, depth: 3 },
    { x: 7, y: 17.5, width: 3.5, depth: 3 },
    { x: 17.5, y: 17.5, width: 3.5, depth: 3 },
    { x: 7, y: 28, width: 3.5, depth: 3 },
    { x: 17.5, y: 28, width: 3.5, depth: 3 },
  ],
};

export const currentLayoutStructures: Array<Position> = [
  {
    id: '001',
    x: 19,
    y: 11.5,
  },
  {
    id: '005',
    x: 16,
    y: 9,
  },
  {
    id: '024',
    x: 14,
    y: 12,
  },
  {
    id: '023',
    x: 13,
    y: 12,
  },
  {
    id: '027',
    x: 11,
    y: 9,
  },
  {
    id: '035',
    x: 8,
    y: 12.5,
  },
  {
    id: '014',
    x: 8.5,
    y: 11.5,
  },
  {
    id: '011',
    x: 8,
    y: 10.5,
  },
  {
    id: '037',
    x: 8,
    y: 10,
  },
  {
    id: '012',
    x: 8,
    y: 9,
  },
  {
    id: '034',
    x: 19,
    y: 10,
  },
  {
    id: '016',
    x: 21.5,
    y: 8.5,
  },
  {
    id: '019',
    x: 17,
    y: 7.5,
  },
  {
    id: '029',
    x: 16,
    y: 7,
  },
  {
    id: '028',
    x: 14,
    y: 7.5,
  },
  {
    id: '010',
    x: 14,
    y: 6.5,
  },
  {
    id: '015',
    x: 9,
    y: 10,
  },
  {
    id: '031',
    x: 17,
    y: 6,
  },
  {
    id: '039',
    x: 17,
    y: 5,
  },
  {
    id: '009',
    x: 20,
    y: 10,
  },
  {
    id: '017',
    x: 14,
    y: 4,
  },
  {
    id: '022',
    x: 13,
    y: 7.5,
  },
  {
    id: '036',
    x: 12,
    y: 7.5,
  },
  {
    id: '008',
    x: 8,
    y: 7,
  },
  {
    id: '007',
    x: 8,
    y: 8,
  },
  {
    id: '013',
    x: 9,
    y: 9,
  },
  {
    id: '006',
    x: 11,
    y: 5,
  },
  {
    id: '021',
    x: 13,
    y: 9.5,
  },
  {
    id: '026',
    x: 13.5,
    y: 2,
  },
  {
    id: '030',
    x: 16,
    y: 4,
  },
  {
    id: '038',
    x: 16,
    y: 5,
  },
  {
    id: '025',
    x: 17,
    y: 2,
  },
  {
    id: '046',
    x: 16,
    y: 5.5,
  },
  {
    id: '045',
    x: 14,
    y: 11,
  },
  {
    id: '043',
    x: 11,
    y: 6,
  },
  {
    id: '047',
    x: 11,
    y: 7,
  },
  {
    id: '048',
    x: 17,
    y: 4,
  },
  {
    id: '018',
    x: 6,
    y: 4,
  },
  {
    id: '049',
    x: 6,
    y: 6,
  },
  {
    id: '004',
    x: 10.5,
    y: 13.5,
  },
  {
    id: '044',
    x: 11,
    y: 2,
  },
  {
    id: '050',
    x: 17.5,
    y: 2,
  },
  {
    id: '053',
    x: 19,
    y: 7,
  },
  {
    id: '052',
    x: 16.5,
    y: 2,
  },
  {
    id: '054',
    x: 14,
    y: 9,
  },
  {
    id: '055',
    x: 13,
    y: 10,
  },
  {
    id: '051',
    x: 20,
    y: 9,
  },
  {
    id: '056',
    x: 8.5,
    y: 3.5,
  },
  {
    id: '057',
    x: 0,
    y: 8.5,
  },
  {
    id: '058',
    x: 0,
    y: 4.5,
  },
  {
    id: '059',
    x: 1,
    y: 4.5,
  },
  {
    id: '060',
    x: 13,
    y: 2,
  },
  {
    id: '020',
    x: 13,
    y: 9,
  },
  {
    id: '003',
    x: 14,
    y: 5.5,
  },
  {
    id: '061',
    x: 20,
    y: 7.5,
  },
  {
    id: '062',
    x: 8,
    y: 11.5,
  },
  {
    id: '063',
    x: 9.5,
    y: 11.5,
  },
  {
    id: '064',
    x: 19,
    y: 6,
  },
  {
    id: '065',
    x: 12,
    y: 2,
  },
  {
    id: '066',
    x: 18,
    y: 2,
  },
  {
    id: '067',
    x: 19,
    y: 9,
  },
  {
    id: '069',
    x: 11,
    y: 10.5,
  },
  {
    id: '068',
    x: 11,
    y: 12,
  },
  /*{
    id: '0',
    x: 1,
    y: 1,
  },*/
];
