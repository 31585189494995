const _temp0 = require("../../images/001.jpg");
const _temp1 = require("../../images/002.jpg");
const _temp2 = require("../../images/003.jpg");
const _temp3 = require("../../images/004.jpg");
const _temp4 = require("../../images/005.jpg");
const _temp5 = require("../../images/006.jpg");
const _temp6 = require("../../images/007.jpg");
const _temp7 = require("../../images/008.jpg");
const _temp8 = require("../../images/009.jpg");
const _temp9 = require("../../images/010.jpg");
const _temp10 = require("../../images/011.jpg");
const _temp11 = require("../../images/012.jpg");
const _temp12 = require("../../images/013.jpg");
const _temp13 = require("../../images/014.jpg");
const _temp14 = require("../../images/015.jpg");
const _temp15 = require("../../images/016.jpg");
const _temp16 = require("../../images/017.jpg");
const _temp17 = require("../../images/018.jpg");
const _temp18 = require("../../images/019.jpg");
const _temp19 = require("../../images/020.jpg");
const _temp20 = require("../../images/021.jpg");
const _temp21 = require("../../images/022.jpg");
const _temp22 = require("../../images/023.jpg");
const _temp23 = require("../../images/024.jpg");
const _temp24 = require("../../images/025.jpg");
const _temp25 = require("../../images/026.jpg");
const _temp26 = require("../../images/027.jpg");
const _temp27 = require("../../images/028.jpg");
const _temp28 = require("../../images/029.jpg");
const _temp29 = require("../../images/030.jpg");
const _temp30 = require("../../images/031.jpg");
const _temp31 = require("../../images/032.jpg");
const _temp32 = require("../../images/033.jpg");
const _temp33 = require("../../images/034.jpg");
const _temp34 = require("../../images/035.jpg");
const _temp35 = require("../../images/036.jpg");
const _temp36 = require("../../images/037.jpg");
const _temp37 = require("../../images/038.jpg");
const _temp38 = require("../../images/039.jpg");
const _temp39 = require("../../images/040.jpg");
const _temp40 = require("../../images/041.jpg");
const _temp41 = require("../../images/042.jpg");
const _temp42 = require("../../images/043.jpg");
const _temp43 = require("../../images/044.jpg");
const _temp44 = require("../../images/045.jpg");
const _temp45 = require("../../images/046.jpg");
const _temp46 = require("../../images/047.jpg");
const _temp47 = require("../../images/048.jpg");
const _temp48 = require("../../images/049.jpg");
const _temp49 = require("../../images/050.jpg");
const _temp50 = require("../../images/051.jpg");
const _temp51 = require("../../images/052.jpg");
const _temp52 = require("../../images/053.jpg");
const _temp53 = require("../../images/054.jpg");
const _temp54 = require("../../images/055.jpg");
const _temp55 = require("../../images/056.jpg");
const _temp56 = require("../../images/057.jpg");
const _temp57 = require("../../images/058.jpg");
const _temp58 = require("../../images/059.jpg");
const _temp59 = require("../../images/060.jpg");
const _temp60 = require("../../images/061.jpg");
const _temp61 = require("../../images/062.jpg");
const _temp62 = require("../../images/063.jpg");
const _temp63 = require("../../images/064.jpg");
const _temp64 = require("../../images/065.jpg");
const _temp65 = require("../../images/066.jpg");
const _temp66 = require("../../images/067.jpg");
const _temp67 = require("../../images/068.jpg");
const _temp68 = require("../../images/069.jpg");
module.exports = {
  "001": _temp0,
  "002": _temp1,
  "003": _temp2,
  "004": _temp3,
  "005": _temp4,
  "006": _temp5,
  "007": _temp6,
  "008": _temp7,
  "009": _temp8,
  "010": _temp9,
  "011": _temp10,
  "012": _temp11,
  "013": _temp12,
  "014": _temp13,
  "015": _temp14,
  "016": _temp15,
  "017": _temp16,
  "018": _temp17,
  "019": _temp18,
  "020": _temp19,
  "021": _temp20,
  "022": _temp21,
  "023": _temp22,
  "024": _temp23,
  "025": _temp24,
  "026": _temp25,
  "027": _temp26,
  "028": _temp27,
  "029": _temp28,
  "030": _temp29,
  "031": _temp30,
  "032": _temp31,
  "033": _temp32,
  "034": _temp33,
  "035": _temp34,
  "036": _temp35,
  "037": _temp36,
  "038": _temp37,
  "039": _temp38,
  "040": _temp39,
  "041": _temp40,
  "042": _temp41,
  "043": _temp42,
  "044": _temp43,
  "045": _temp44,
  "046": _temp45,
  "047": _temp46,
  "048": _temp47,
  "049": _temp48,
  "050": _temp49,
  "051": _temp50,
  "052": _temp51,
  "053": _temp52,
  "054": _temp53,
  "055": _temp54,
  "056": _temp55,
  "057": _temp56,
  "058": _temp57,
  "059": _temp58,
  "060": _temp59,
  "061": _temp60,
  "062": _temp61,
  "063": _temp62,
  "064": _temp63,
  "065": _temp64,
  "066": _temp65,
  "067": _temp66,
  "068": _temp67,
  "069": _temp68
}