import { cn } from '../utils';

type Props = {
  width: number;
  depth: number;
  className?: string;
} & React.PropsWithChildren;

export function Grid({ width, depth, className, children }: Props) {
  return (
    <div
      className={cn(
        'absolute grid flex-shrink-0 *:border *:border-black',
        className,
      )}
      style={{
        gridTemplateColumns: `repeat(${width * 2}, calc(var(--tileSize) / 2))`,
        gridTemplateRows: `repeat(${depth * 2}, calc(var(--tileSize) / 2))`,
      }}
    >
      {children}
    </div>
  );
}
