import React from 'react';
import { CityMapField } from './components/CityMapField';
import { StructuresSelector } from './components/StructuresSelector';
import {
  currentLayout,
  currentLayoutStructures,
  newLayout,
} from './data/defaultLayouts';
import { cn } from './utils';

export function App() {
  const [layout, setLayout] = React.useState(currentLayout);
  return (
    <div className='flex h-[100vh] w-full justify-between overflow-auto bg-purple-200 p-4 *:flex-shrink-0'>
      <div className='flex w-40 flex-col gap-4'>
        <h1 className='text-xl'>
          Bricksie's Lego
          <br />
          City Layout
        </h1>
        <div className='grid h-10 w-36 grid-cols-2 gap-1 rounded-xl bg-zinc-400 p-1'>
          <button
            className={cn(
              'cursor-pointer rounded-lg',
              layout === currentLayout && 'bg-zinc-100',
            )}
            onClick={() => setLayout(currentLayout)}
          >
            Current
          </button>
          <button
            className={cn(
              'cursor-pointer rounded-lg',
              layout === newLayout && 'bg-zinc-100',
            )}
            onClick={() => setLayout(newLayout)}
          >
            New
          </button>
        </div>
        <p className='text-sm'>
          {layout.width} x {layout.depth} base plates
        </p>
      </div>
      <CityMapField layout={layout} structures={currentLayoutStructures} />
      <StructuresSelector />
    </div>
  );
}
