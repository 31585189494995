import type { CityMap, Position } from '../types';
// @ts-ignore
import * as files from '../../images/*.jpg';
import { structures } from '../data/structures';
import { Grid } from './Grid';

type Props = {
  layout: CityMap;
  structures?: Array<Position>;
};

export function CityMapField({ layout, structures: layoutStructures }: Props) {
  const tiles = new Array(
    Math.floor(layout.width) * 1 * Math.floor(layout.depth) * 1,
  ).fill(0);
  return (
    <div className='relative h-full w-[900px] overflow-y-auto'>
      <Grid width={layout.width} depth={layout.depth} className='bg-gray-600'>
        {tiles.map((_, i) => {
          return <div key={i} className='tile'></div>;
        })}
      </Grid>
      <Grid width={layout.width} depth={layout.depth} className='*:bg-white'>
        {layout.holes.map((hole, i) => {
          return (
            <div
              key={`${hole.x}-${hole.y}-${i}`}
              style={{
                gridArea: `${hole.y * 2 + 1} / ${hole.x * 2 + 1} / span ${hole.depth * 2} / span ${hole.width * 2}`,
              }}
            ></div>
          );
        })}
      </Grid>
      <Grid width={layout.width} depth={layout.depth}>
        {layoutStructures?.map((position) => {
          const structure = structures.find((item) => item.id === position.id)!;
          return (
            <div
              key={position.id}
              style={{
                gridArea: `${position.y * 2 + 1} / ${position.x * 2 + 1} / span ${structure.depth * 2} / span ${structure.width * 2}`,
              }}
            >
              <img
                src={files[position.id]}
                className='size-full object-cover hover:scale-[2]'
              />
            </div>
          );
        })}
      </Grid>
    </div>
  );
}
