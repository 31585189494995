import type { Structure } from '../types';
// @ts-ignore
import * as files from '../../images/*.jpg';

type Props = {
  data: Structure;
};

export function StructureBlock({ data }: Props) {
  return (
    <div className='group flex w-60 cursor-pointer items-center gap-2 rounded-lg bg-white p-1 hover:bg-zinc-400'>
      <img
        className='size-12 rounded bg-zinc-600 object-cover group-hover:translate-x-9 group-hover:scale-[2.5]'
        src={files[data.id]}
      />
      <div className='flex flex-col gap-2 py-1'>
        <h3 className='text-lg leading-4'>{data.name}</h3>
        <p className='text-xs'>
          <span className='font-bold'>{data.id}: </span>
          {data.width} x {data.depth} base plates
        </p>
      </div>
    </div>
  );
}
