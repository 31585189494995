import { structures } from '../data/structures';
import { StructureBlock } from './StructureBlock';

export function StructuresSelector() {
  return (
    <div className='flex h-full flex-shrink-0 flex-col gap-2 overflow-y-auto'>
      {structures.map((structure) => {
        return <StructureBlock data={structure} key={structure.id} />;
      })}
    </div>
  );
}
