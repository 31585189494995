import { Structure } from '../types';

export const structures: Structure[] = [
  {
    id: '001',
    name: 'Triple Boutique Hotel',
    width: 2,
    depth: 1,
  },
  {
    id: '002',
    name: 'Burrow',
    width: 1.5,
    depth: 1.5,
  },
  {
    id: '003',
    name: 'Up House',
    width: 1,
    depth: 1,
  },
  {
    id: '004',
    name: 'Beam Skyscraper',
    width: 1,
    depth: 1,
  },
  {
    id: '005',
    name: 'Central Park',
    width: 2,
    depth: 4,
  },
  {
    id: '006',
    name: 'Tuning Workshop',
    width: 1,
    depth: 1,
  },
  {
    id: '007',
    name: 'Bowling Alley Parking Lot',
    width: 2,
    depth: 1,
  },
  {
    id: '008',
    name: 'Bowling Alley',
    width: 2,
    depth: 1,
  },
  {
    id: '009',
    name: 'Assembly Square',
    width: 1,
    depth: 1.5,
  },
  {
    id: '010',
    name: 'Downtown Diner',
    width: 1,
    depth: 1,
  },
  {
    id: '011',
    name: 'Fire Brigade',
    width: 1,
    depth: 1,
  },
  {
    id: '012',
    name: 'Palace Cinema',
    width: 1,
    depth: 1,
  },
  {
    id: '013',
    name: 'Parisian Restoraunt',
    width: 1,
    depth: 1,
  },
  {
    id: '014',
    name: 'Town Hall',
    width: 1,
    depth: 1,
  },
  {
    id: '015',
    name: 'Natural History Museum',
    width: 1,
    depth: 1.5,
  },
  {
    id: '016',
    name: 'Studgate Train Station',
    width: 1.5,
    depth: 1.5,
  },
  {
    id: '017',
    name: 'Construction Site',
    width: 1,
    depth: 1.5,
  },
  {
    id: '018',
    name: 'Venetian Houses',
    width: 1,
    depth: 2,
  },
  {
    id: '019',
    name: 'Avengers Tower',
    width: 1,
    depth: 1,
  },
  {
    id: '020',
    name: 'Vinyl Store (from Creator Main Street)',
    width: 1,
    depth: 0.5,
  },
  {
    id: '021',
    name: 'Ministry of Magic',
    width: 1,
    depth: 0.5,
  },
  {
    id: '022',
    name: "Detective's Office",
    width: 1,
    depth: 1,
  },
  {
    id: '023',
    name: 'Grand Emporium',
    width: 1,
    depth: 1,
  },
  {
    id: '024',
    name: 'Brick Bank',
    width: 1,
    depth: 1,
  },
  {
    id: '025',
    name: 'Creator Cozier House',
    width: 0.5,
    depth: 1,
  },
  {
    id: '026',
    name: 'City Downtown',
    width: 3,
    depth: 1,
  },
  {
    id: '027',
    name: 'Hospital',
    width: 1,
    depth: 1.5,
  },
  {
    id: '028',
    name: 'Sanctum Sanctorum',
    width: 1,
    depth: 1,
  },
  {
    id: '029',
    name: 'Ghostbusters Headquarters',
    width: 1,
    depth: 1.5,
  },
  {
    id: '030',
    name: 'Double Corner Garage',
    width: 1,
    depth: 1,
  },
  {
    id: '031',
    name: 'Nuget Skyscraper',
    width: 1,
    depth: 1,
  },
  {
    id: '032',
    name: 'Black & White Skyscraper',
    width: 1,
    depth: 1,
  },
  {
    id: '033',
    name: 'Spiral Skyscraper',
    width: 1,
    depth: 1,
  },
  {
    id: '034',
    name: 'Double Jazz Club',
    width: 1,
    depth: 1.5,
  },
  {
    id: '035',
    name: 'Town Square',
    width: 2,
    depth: 0.5,
  },
  {
    id: '036',
    name: 'Double Pet Shop',
    width: 1,
    depth: 1,
  },
  {
    id: '037',
    name: 'Donut Cafe (from Police Station)',
    width: 1,
    depth: 0.5,
  },
  {
    id: '038',
    name: 'City Press (from Police Station)',
    width: 1,
    depth: 0.5,
  },
  {
    id: '039',
    name: 'Double Police Station',
    width: 1,
    depth: 1,
  },
  {
    id: '040',
    name: 'Kwik-E-Mart',
    width: 2,
    depth: 2,
  },
  {
    id: '041',
    name: "Simpson's House",
    width: 2,
    depth: 2,
  },
  {
    id: '042',
    name: 'Home Alone House',
    width: 2,
    depth: 2,
  },
  {
    id: '043',
    name: 'Double Book Shop',
    width: 1,
    depth: 1,
  },
  {
    id: '044',
    name: 'Book Shop Residentials',
    width: 1,
    depth: 1,
  },
  {
    id: '045',
    name: 'Gringotts Bank',
    width: 1,
    depth: 1,
  },
  {
    id: '046',
    name: 'Double Daily Bugle',
    width: 1,
    depth: 1.5,
  },
  {
    id: '047',
    name: 'University',
    width: 1,
    depth: 1.5,
  },
  {
    id: '048',
    name: 'Octan Gas Station',
    width: 1,
    depth: 1,
  },
  {
    id: '049',
    name: 'School',
    width: 1,
    depth: 2,
  },
  {
    id: '050',
    name: "Florists's Shop",
    width: 0.5,
    depth: 1,
  },
  {
    id: '051',
    name: 'Sesame Street',
    width: 1,
    depth: 1,
  },
  {
    id: '052',
    name: 'Peter Parker Appartements',
    width: 0.5,
    depth: 1,
  },
  {
    id: '053',
    name: 'Sitcom Tower',
    width: 1,
    depth: 1.5,
  },
  {
    id: '054',
    name: 'Diagon Alley Left Part',
    width: 1,
    depth: 2,
  },
  {
    id: '055',
    name: 'Diagon Alley Right Part',
    width: 1,
    depth: 2,
  },
  {
    id: '056',
    name: 'Farm',
    width: 1.5,
    depth: 3,
  },
  {
    id: '057',
    name: 'Winnie the Pooh',
    width: 2,
    depth: 1,
  },
  {
    id: '058',
    name: 'Tree House',
    width: 1,
    depth: 2,
  },
  {
    id: '059',
    name: 'Tree House Bridge',
    width: 1,
    depth: 2,
  },
  {
    id: '060',
    name: 'Bakery',
    width: 0.5,
    depth: 1,
  },
  {
    id: '061',
    name: 'Spring Lantern Festival',
    width: 1.5,
    depth: 1,
  },
  {
    id: '062',
    name: 'Town Hall Left Corner',
    width: 0.5,
    depth: 1,
  },
  {
    id: '063',
    name: 'Town Hall Right Corner',
    width: 0.5,
    depth: 1,
  },
  {
    id: '064',
    name: 'Lego Store',
    width: 1,
    depth: 1,
  },
  {
    id: '065',
    name: 'Pet Shop Townhouses',
    width: 1,
    depth: 1,
  },
  {
    id: '066',
    name: 'Custom Cafe',
    width: 1,
    depth: 1,
  },
  {
    id: '067',
    name: 'Disney Costume Shop',
    width: 1,
    depth: 1,
  },
  {
    id: '068',
    name: 'Bonsai Tree Park',
    width: 1,
    depth: 1.5,
  },
  {
    id: '069',
    name: 'Farmers Market',
    width: 1,
    depth: 1.5,
  },
  /*{
    id: '070',
    name: '',
    width: 1,
    depth: 1,
  },*/
];
